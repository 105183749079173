<template>
  <div>
    <div v-if="isAutonomous">
      <h2 class="custom-title-secondary mb-1"
        >Qual é sua profissão/área de atuação?
      </h2>
    </div>
    <div v-else>
      <h2 class="custom-title-secondary mb-2">
        Conte-nos um pouco mais sobre a sua atividade.
      </h2>
    </div>
    <v-row justify="center" class="my-3">
      <v-col cols="12" sm="12">
        <v-textarea
          label="Descrição da atividade"
          outlined
          v-model="formUser.activityDescription"
          :change="onAnswer()"
          required
          rows="7"
          :rules="[(v) => !!v || 'Preencha acima']"
        ></v-textarea>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  props: {
    answer: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      currentAnswer: this.answer,
    }
  },
  computed: {
    ...mapState({
      formUser: (state) => state.formDataUser.formUser,
    }),
    ...mapGetters({
      isAutonomous: 'formDataUser/isAutonomous',
    }),
  },
  methods: {
    onAnswer() {
      this.$emit('onAnswer', {
        activityDescription: this.formUser.activityDescription,
      })
    },
  },
}
</script>

<style module lang="scss">
@import '@design';
</style>
